import React from 'react';

import SearchBox from './';

export default function DynamicSearchBox({ page, processSearchBoxResponse }) {

    const searchFilters = {
        template_key: 'blog',
        hosted: 'true',
        blog_archive: 'false',
    };

    let selectedFields;
    
    try{
        selectedFields = {
            id: {
                raw: {}
            }, 
            blog_date: {
                raw: {}
            }, 
            blog_title: {
                raw: {}
            }, 
            vanity_url: {
                raw: {}
            }, 
            blog_thumbnail: {
                raw: {}
            }
        }
    }catch(e){}

    return (
        <div className="blog-search-box">
            <SearchBox
                engine='hp-store-pages'
                filters={searchFilters}
                selectedFields={selectedFields}
                page={page}
                processSearchBoxResponse={processSearchBoxResponse}
            />
        </div>
    );
}
