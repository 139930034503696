import React from 'react';

import BlogAdsense from './adsense/ad-slot';
import BlogAdBanner from '../right-rail/ad-banner';

const BlogAdContainer = props => {
    let { originCountryCode } = props;

    return (
        originCountryCode === 'non-US'
            ? <BlogAdsense {...props} />
            : <BlogAdBanner {...props} />
    );
}

export default BlogAdContainer;