import React from 'react';

import withWindowResize from '../../../../ui/components/window-resize';

class BlogAdBanner extends React.Component{

    constructor(props){
        super(props);
        this.adContainer = React.createRef();
        const {bannerMeta} = this.props || {};
        const {categories, tags} = bannerMeta || {};
        this.state = {
            bannerCategory: bannerMeta ? BlogAdBanner.getCategory(categories, tags) : null,
        }
    }

    static CATEGORY_MAPS = {
        'smb': (catsMap, tagsMap) => {
            let tagsOrCats = [
                'business',
                'tech-at-work',
                'business-trends',
                'smb',
                'small-business',
                'enterprise'
            ];

            return tagsOrCats.find( id => tagsMap[id] || catsMap[id] ) || 
                   ((catsMap['lists'] || catsMap['monitors']) && tagsMap['business-monitors'])
                   ? 'smb'
                   : null;
        },
        'gaming': (catsMap, tagsMap) => {
            let tagsOrCats =  [
                'gaming',
                'hp-omen'
            ];

            return tagsOrCats.find( id => tagsMap[id] || catsMap[id] )
                   ? 'gaming'
                   : null;
        },
        'workstations': (catsMap, tagsMap) => {
            let tagsOrCats = [
                'tech-at-work',
                'techatwork'
            ];

            let tags = ['hp-z', 'hp-zbook', 'mobile-workstations', 'workstation-laptops', 'workstations'];

            return tagsOrCats.find( id => tagsMap[id] || catsMap[id] ) ||
                   (catsMap['lists'] && (catsMap['desktops'] || catsMap['laptops']) && tags.find( id => tagsMap[id] ))
                   ? 'workstations'
                   : null;
        },
        'printers': (catsMap, tagsMap) => (
            tagsMap['printers'] || catsMap['printers']
            ? 'printers'
            : null
        ),
        // Toner removed 3/16/23
        // 'toner': (catsMap, tagsMap) => (
        //     catsMap['business'] && catsMap['lists'] && catsMap['printers']
        //     ? 'toner'
        //     : null
        // ),
        // printers tag or categories  -> printers kw
        'ink': (catsMap, tagsMap) => (
            tagsMap['inkjet-printers'] || tagsMap['printer-cartridges'] || tagsMap['hp-instant-ink'] ||
            tagsMap['printer-ink'] || tagsMap['printingasaservice'] || tagsMap['tankprinter'] || tagsMap['supplies'] || tagsMap['printsupplies']
            ? 'ink'
            : null
        ),
        'cps_core': (catsMap, tagsMap) => (
            catsMap['lists'] && catsMap['students']
            ? 'cps_core'
            : null
        ),
        'carepack': (catsMap, tagsMap) => (
            tagsMap['carepack']
            ? 'carepack'
            : null
        )
    }

    componentDidMount(){
        if(this.evaluateScreenSize()){
            this.createAdElement();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let {categories, tags, bannerKey} = nextProps.bannerMeta || {};
        let bannerCategory = BlogAdBanner.getCategory(categories, tags);
        // if it's a new category, set loaded=false. 
        // componentDidUpdate will take care of determining whether to load the banner if the browser has the correct screen width
        if(bannerKey && bannerKey !== prevState.bannerKey && bannerCategory !== prevState.bannerCategory){
            return {
                bannerCategory,
                bannerKey,
                loaded: false
            }
        }

        return null;
    }

    /** only re-render if the banner hasn't been loaded yet */
    shouldComponentUpdate(nextProps, nextState){
        return !nextState.loaded;
    }

    componentDidUpdate(prevProps, prevState){
        let {loaded} = this.state;
        if(!loaded && this.evaluateScreenSize()){
            this.createAdElement();
        }
    }

    static createMap = (array) =>  Array.isArray(array)
                                    ? array.reduce((map, entry) => {
                                            map[entry] = 1; 
                                            return map;
                                    }, {})
                                    : {}

    static getCategory = (categories, tags) => {
        let tagsMap = BlogAdBanner.createMap(tags);
        let catsMap = BlogAdBanner.createMap(categories);
        let adCategories = BlogAdBanner.CATEGORY_MAPS;
        let args = [catsMap, tagsMap];
        return adCategories['workstations'](...args) ||
               adCategories['carepack'](...args) ||
               adCategories['printers'](...args) || //check if it qualifies for toner first because it uses the same 'business' category from 'smb' ads
               adCategories['smb'](...args) ||
               adCategories['gaming'](...args) ||
               adCategories['ink'](...args) ||
               adCategories['cps_core'](...args) ||
               'default';
    }

    getAdGenerator = () => {
        let {bannerType, uniqueIdentifier} = this.props;
        let timestamp = Date.now();

        let bannerMap = {
            'banner1': category => (
                 this.createAdIframe({
                    src: `https://ad.doubleclick.net/ddm/adi/N5823.3620129HP1/B24455230.298028114;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`,
                    width: 300,
                    height: 250,
                    scriptSrc: `https://ad.doubleclick.net/ddm/adj/N5823.3620129HP1/B24455230.298028114;abr=!ie;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`
                })
            ),
            'banner2': category => (
                this.createAdIframe({
                    src: `https://ad.doubleclick.net/ddm/adi/N5823.3620129HP1/B24455230.298137396;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`,
                    width: 300,
                    height: 250,
                    scriptSrc: `https://ad.doubleclick.net/ddm/adj/N5823.3620129HP1/B24455230.298137396;abr=!ie;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`
                })
            ),
            'banner3': category => (
                this.createAdIframe({
                    src: `https://ad.doubleclick.net/ddm/adi/N5823.3620129HP1/B24455230.298137399;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`,
                    width: 300,
                    height: 250,
                    scriptSrc: `https://ad.doubleclick.net/ddm/adj/N5823.3620129HP1/B24455230.298137399;abr=!ie;sz=300x250;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`
                })
            ),
            'bannerSkyscraper': category => (
                this.createAdIframe({
                    src: `https://ad.doubleclick.net/ddm/adi/N5823.3620129HP1/B24455230.298028159;sz=300x600;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`,
                    width: 300,
                    height: 600,
                    scriptSrc: `https://ad.doubleclick.net/ddm/adj/N5823.3620129HP1/B24455230.298028159;abr=!ie;sz=300x600;kw=${category};ord=${timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?`
                })
            ),
            'rightRailBanner': category => {
                let iframe = document.createElement('iframe');
                iframe.class=`blog-ad-iframe ${uniqueIdentifier}-iframe`;
                this.adContainer.current.innerHTML = '';
                this.adContainer.current.appendChild(iframe);
                iframe.contentWindow.document.open();
                iframe.contentWindow.document.write(
                    `
                        <head></head>
                        <body style="padding:0; margin:0; width:100%; overflow:hidden">
                        <ins class='dcmads' style='display:inline-block;width:300px;height:250px'
                            data-dcm-placement='N5823.3620129HP1/B24455230.278813019'
                            data-dcm-rendering-mode='script'
                            data-dcm-https-only
                            data-dcm-resettable-device-id=''
                            data-dcm-app-id=''
                            data-dcm-keywords='${category}'>
                            <script src='https://www.googletagservices.com/dcm/dcmads.js'></script>
                        </ins>
                        </body>
                    `
                );
                iframe.contentWindow.document.close();
            }
        }
        return bannerMap[bannerType];
    } 

    createAdElement = () => {
        try{
            this.setState({loaded: true}, () => {
                this.addScriptToAdElement();
            })
        }catch(e){}
    }

    createAdIframe = (data) => {
        let { src, scriptSrc } = data;
        let iframe = document.createElement('iframe');
        iframe.src = src;
        iframe.width = data.width;
        iframe.height = data.height;
        iframe.marginwidth=0;
        iframe.marginheight=0;
        iframe.hspace=0;
        iframe.vspace=0; 
        iframe.frameborder=0; 
        iframe.scrolling='no';
        iframe.bordercolor='#000000';

        let script = document.createElement('script');
        script.src = scriptSrc;
        script.setAttribute('language', 'javascript1.1');
        iframe.appendChild(script);

        this.adContainer.current.innerHTML = '';
        this.adContainer.current.appendChild(iframe);
    }

    addScriptToAdElement = () => {
        let {bannerCategory} = this.state;

        let adGenerator = this.getAdGenerator()
        if(typeof adGenerator === 'function'){
            adGenerator(bannerCategory);
        }
    }

    evaluateScreenSize = () => {
        let { customBreakpoints, type, breakpoint } = this.props;
        let device;
        try{
            device = customBreakpoints[breakpoint || 'blog'];
        }catch(e){}

        return type === 'all' || ((device === 'desktop' || device === 'mobile') && device === type);
    }

    render(){
        const { uniqueIdentifier } = this.props;
        return <div className={`blog-ad-image${uniqueIdentifier ? ' ' + uniqueIdentifier : ''}`} ref={this.adContainer} />;
    }
}

export default withWindowResize(BlogAdBanner);
