import React from 'react';
import {connect} from 'react-redux';

import './adsense.less';

class BlogAdsenseSlot extends React.Component{

    static ADSENSE_MAP = {
        'banner1': {
            adSlot: '1409047688'
        },
        'banner2': {
            adSlot: '7875201920'
        },
        'banner3': {
            adSlot: '5579392835'
        },
        'bannerSkyscraper': {
            adSlot: '1501365266',
            height: '600px'
        },
        'rightRailBanner': {
            adSlot: '4518410846'
        }
    };

    constructor(){
        super();
        this.insRef = React.createRef();
    }

    componentDidMount(){
        if(this.props.scriptLoaded){
            this.loadAdSlot();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.scriptLoaded && this.props.scriptLoaded){
            this.loadAdSlot();
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // if(nextProps.scriptLoaded && !this.props.scriptLoaded)
        return nextProps.scriptLoaded && !this.props.scriptLoaded;
    }

    loadAdSlot = () => {
        let { bannerType } = this.props;
        let adsenseData = BlogAdsenseSlot.ADSENSE_MAP[bannerType];

        if(!adsenseData || !adsenseData.adSlot) return;

        try{
            let {adSlot, height} = adsenseData;
            let ins = document.createElement('ins');
            ins.setAttribute('class', 'adsbygoogle');
            ins.setAttribute('style', `display:inline-block;width:300px;height:${ height || '250px'}`);
            ins.setAttribute('data-ad-client', 'ca-pub-9379236959924950');
            ins.setAttribute('data-ad-slot', adSlot);
            
            this.insRef.current.appendChild(ins);
            (adsbygoogle = window.adsbygoogle || []).push({});
        }catch(e){}
    }

    render(){
        return <div className='adsense-container' ref={this.insRef}></div>;
    }
}


const mapStateToProps = state => ({
    scriptLoaded: state && state.scriptLoader && state.scriptLoader['blog-adsense']
});

export default connect(mapStateToProps)(BlogAdsenseSlot);