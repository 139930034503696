import React from 'react';
import { Modal } from '@hpstellar/core';

import './blog-email-form.less';
class BlogEmailForm extends React.Component {
    constructor() {
        super();
        this.iframeRef = React.createRef();
        this.state = {
            iframeLoaded: false,
        };
        this.iframeEventHandler = this.iframeEventHandler.bind(this);
    }

    static blogEmailFormSubmitLink = 'us:hho:optly:campaign:newsletter_submit_button_success';
    static blogEmailFormModalSrc = 'https://cloud.store.hp.com/newsletter_pop-up_form';
    static blogEmailFormEventUniqueIdentifier = { forBlogEmailForm: true };

    sendMessageToIframe() {
        try {
            this.iframeRef.current.contentWindow.postMessage(
                { url: window.location.href, submitId: BlogEmailForm.blogEmailFormSubmitLink },
                BlogEmailForm.blogEmailFormModalSrc
            );
        } catch (e) {}
    }

    iframeEventHandler(event) {
        if (event && event.data === 'Close') {
            this.props.handleClose();
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('message', this.iframeEventHandler);
        } catch (e) {}
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('message', this.iframeEventHandler);
        } catch (e) {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps && !nextProps.isOpen
            ? {
                  iframeLoaded: false,
              }
            : null;
    }

    componentDidUpdate(prevProps, prevState) {
        let { iframeLoaded: prevIframeLoaded } = prevState || {};
        let { iframeLoaded } = this.state;
        let { match: { url: prevUrl } = {}, isOpen: prevIsOpen } = prevProps || {};
        let { match: { url } = {}, isOpen } = this.props;

        if (iframeLoaded && !prevIframeLoaded) {
            this.sendMessageToIframe();
        } else if (prevUrl !== url) {
            this.props.handleClose();
        }
    }

    render() {
        let {
            handleClose,
            isOpen,
            withBackdrop,
            verticalAlignment = 'center',
            horizontalAlignment = 'center',
        } = this.props;
        return isOpen ? (
            <Modal
                isOpen={isOpen}
                className="blog-email-modal"
                modalClassName="blog-email-modal-wrapper"
                onModalClose={handleClose}
                verticalAlignment={verticalAlignment}
                horizontalAlignment={horizontalAlignment}
                withBackdrop={withBackdrop}
            >
                <iframe
                    ref={this.iframeRef}
                    id="blog-email-iframe"
                    className="blog-email-modal-iframe"
                    src={BlogEmailForm.blogEmailFormModalSrc}
                    width="100%"
                    onLoad={() => this.setState({ iframeLoaded: true })}
                    height="540"
                    frameBorder="0"
                    marginWidth="0"
                    marginHeight="0"
                    scrolling="no"
                />
            </Modal>
        ) : null;
    }
}

export default BlogEmailForm;
