import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { MetricsElement } from 'react-metrics';
import debounce from 'lodash/debounce';

import { SearchBar } from '@hpstellar/core';
import { SearchBarInput, SearchBarList, TechTakesListItem } from '@hpstellar/core';

import { Helpers } from '../../../core/src/helpers';
import withWindowResize from '../../../ui/components/window-resize';
import { getBlogListSearchParameters } from '../../../search/util';
import useSearchEndpoint from '../../../search/useSearchEndpoint';
import './search-box.less';

const DESKTOPMINWIDTH = 768;

const COLLECTION = 'page';

const BlogSearchListItem = ({ isHighlighted, blog, index }) => {
    let { blogDate, blogTitle, description, vanityUrl, blogThumbnail } = blog || {};
    let linkProps = {
        to: vanityUrl,
    };
    let selectedResultProps = isHighlighted ? { isHighlighted, 'data-selected-blog-search-result-index': index } : {};

    return (
        <TechTakesListItem
            linkProps={linkProps}
            imageProps={blogThumbnail}
            title={blogTitle}
            date={blogDate}
            {...selectedResultProps}
        />
    );
};

class SearchBox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            queryString: '',
            response: [],
            minLength: 1,
            isLoading: false,
            caseSensitive: false,
            isOpen: false,
        };
        this.performQueryRequest = Helpers.memoize(
            this.performQueryRequest,
            (queryString, paginationData) =>
                `${queryString}-${paginationData ? paginationData.size : null}-${
                    paginationData ? paginationData.current : null
                }`
        );

        this.getNextPage = debounce(this.getNextPage, 500);
        this.getNextPageMobile = debounce(this.getNextPageMobile, 500);
        this.updateQuery = debounce(this.updateQuery, 700);
        this.scrollOffset = 60;
    }

    getPageSize = () => {
        let { width } = this.props || {};
        let isMobile = width && width < 768;
        return isMobile ? 10 : 5;
    };

    updateQuery = e => {
        const { device } = this.props;
        const queryString = e;
        const shouldResetPagination = queryString !== this.state.queryString;
        this.setState({
            queryString,
        });
        let pageParameters = { size: this.getPageSize(), current: 1 };
        this.performQuery(queryString, pageParameters, shouldResetPagination);
    };

    performQuery = (queryString, paginationData, shouldResetPagination) => {
        this.setState({ isLoading: true });
        this.performQueryRequest(queryString, paginationData)
            .then(response => {
                if (!response) {
                    this.setState({
                        isLoading: false,
                    });
                    return;
                }
                let data = response && response.data;
                let { NofResults, CurrentPage, NofPages, MaxPerPage } = (data && data.Pagination) || {};
                let responseMeta = {
                    page: {
                        current: CurrentPage,
                        size: MaxPerPage,
                        total_pages: NofPages,
                        total_results: NofResults,
                    },
                };
                response = this.props.processSearchBoxResponse ? this.props.processSearchBoxResponse(data) : data;
                this.setState({
                    response:
                        Array.isArray(this.state.response) && !shouldResetPagination
                            ? this.state.response.concat(response)
                            : response,
                    responseMeta,
                    isLoading: false,
                    isOpen: true,
                });
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.log(err);
            });
    };

    performQueryRequest = (queryString, paginationData) => {
        const { hawksearchAPI } = this.props;
        const { size, current } = paginationData || {};
        const { responseMeta } = this.state;
        const { page } = responseMeta || {};
        const { total_pages } = page || {};

        return new Promise((resolve, reject) => {
            if (total_pages < current && total_pages > 0) {
                return resolve(null);
            }
            let queryObj = getBlogListSearchParameters(
                {
                    Keyword: queryString,
                    PageNo: current,
                    MaxPerPage: size,
                },
                {
                    query: 'segment:blog',
                }
            );

            hawksearchAPI.search(queryObj).then(resolve).catch(reject);
        });
    };

    handleEnterKey = e => {
        if (e.keyCode === 13) {
            //Grab selected item from search box component
            const { queryString, response } = this.state;
            let selectedElement = document.querySelector('.blog-search-bar [data-selected-blog-search-result-index]');
            let selectedIndex =
                selectedElement && selectedElement.getAttribute('data-selected-blog-search-result-index');
            let { vanityUrl, id } = response && response[selectedIndex];
            const { engine } = this.props;
            if (vanityUrl && vanityUrl.length > 0) {
                const { history, page, match } = this.props;
                const {
                    params: { basename },
                } = match;
                history.push({
                    pathname: `/${basename}/${vanityUrl}?blogSearchTerm=${queryString}&nor${response.length}`,
                });
            }
        }
    };

    fetchNextPage = () => {
        const { responseMeta } = this.state;
        if (!responseMeta) return;

        const { device } = this.props;
        const paginationDetails = responseMeta && responseMeta.page;
        const queryString = this.state.queryString;
        const { current, size, total_pages, total_results } = paginationDetails || {};
        const isLastPage = current >= total_pages;
        const isFirstPage = current === 1;
        const pageSize = this.getPageSize();

        this.performQuery(queryString, { size: pageSize, current: current + 1 });
    };

    getNextPage = () => {
        this.fetchNextPage();
    };

    getNextPageMobile = () => {
        try {
            let lastResult = document.querySelector('.blog-search-bar-list li:last-child');
            let { bottom } = lastResult.getBoundingClientRect();
            let isLastResultInView = bottom <= window.innerHeight;

            if (isLastResultInView) {
                this.fetchNextPage();
            }
        } catch (e) {}
    };

    getNextPageIfScrolledToBottom = e => {
        try {
            const {
                target: { offsetHeight, scrollTop, scrollHeight },
            } = e;
            if (offsetHeight + scrollTop + this.scrollOffset >= scrollHeight) {
                this.getNextPage();
            }
        } catch (e) {}
    };

    getNextPageIfDraggedToBottom = e => {
        try {
            // let scroll event handler fetch new results if this is scrollable (when scrolling 60px above the last result)
            if (e.currentTarget.lastChild.offsetTop + this.scrollOffset >= e.currentTarget.clientHeight) return;

            // only trigger if dragging down
            let verticalPos = window.scrollY;
            if (this.verticalPos && this.verticalPos >= verticalPos) {
                this.getNextPageMobile();
            }
            this.verticalPos = verticalPos;
        } catch (e) {}
    };

    renderPagination() {
        const { device } = this.props;
        const paginationDetails = this.state && this.state.responseMeta && this.state.responseMeta.page;
        const queryString = this.state.queryString;
        const { current, size, total_pages, total_results } = paginationDetails || {};
        const isLastPage = current >= total_pages;
        const isFirstPage = current === 1;
        const pageSize = this.getPageSize();

        return (
            !this.state.isLoading &&
            this.state.response && (
                <div className="dropdown-item">
                    <button
                        className="search-box-pagination-element dropdown-item-text nav-arrow prev blog-search-pagination-button"
                        disabled={isFirstPage}
                        onClick={() => {
                            this.performQuery(queryString, { size: pageSize, current: current - 1 });
                        }}
                    />
                    {current && total_pages && (
                        <span
                            className="search-box-pagination-element"
                            style={{ boxSizing: 'border-box', paddingTop: 10 }}
                        >{`Page ${current} of ${total_pages}`}</span>
                    )}
                    <button
                        className="search-box-pagination-element dropdown-item-text nav-arrow next blog-search-pagination-button"
                        disabled={isLastPage}
                        onClick={() => {
                            this.performQuery(queryString, { size: pageSize, current: current + 1 });
                        }}
                    />
                </div>
            )
        );
    }

    render() {
        const { response, isLoading, responseMeta } = this.state;
        const { device, width } = this.props;
        const { page } = responseMeta || {};
        const { total_results } = page || {};
        const isMobile = width && width < 768;

        return (
            <MetricsElement
                element="span"
                data-metrics-event-name="linkClick"
                data-metrics-event="e_linkClick"
                data-metrics-link-id={'blog-search-box:clicked'}
            >
                <SearchBar isLoading={isLoading} className="blog-search-bar">
                    <SearchBarInput
                        onChange={this.updateQuery}
                        placeholder="Search for articles..."
                        onKeyUp={this.handleEnterKey}
                    />
                    <SearchBarList
                        infiniteScrollProps={{
                            isLoading,
                            hasMore: false, // disable fetching in the GFE component side to prevent infinite loop of calling "onFetchMore" whenever the modal is open
                            onFetchMore: null,
                            totalCount: total_results,
                        }}
                        className="blog-search-bar-list"
                        onTouchMove={isMobile ? this.getNextPageIfDraggedToBottom : null}
                        onScroll={this.getNextPageIfScrolledToBottom}
                    >
                        {Array.isArray(response) &&
                            response.map((blog, index) => <BlogSearchListItem blog={blog} key={index} index={index} />)}
                    </SearchBarList>
                </SearchBar>
            </MetricsElement>
        );
    }
}

const SearchCointiner = props => {
    const [hawksearchAPI] = useSearchEndpoint();
    return <SearchBox {...props} hawksearchAPI={hawksearchAPI} />;
};

export default withRouter(withWindowResize(SearchCointiner));
